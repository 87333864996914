/** java端接口(原有接口调用方式太繁琐，从3.7.3版本起采用此方法调用) */
import { javaBaseUrl } from "@/utils/anyUrl.js";
import request from '@/utils/request';
var BaseUrl = javaBaseUrl;
let financeURL = BaseUrl + '/ptfinance' //金融管理模块
let userURL = BaseUrl + '/ptuser' //角色模块
let mgEngineURL = BaseUrl + '/mgengine' //同步
let ruleURL = BaseUrl + '/yjlrule'


// 获取用户当地均价和用户的经营地址
export function getAveragePrice(params) {
    return request({
        url: ruleURL + `/baoliAccount/getAveragePrice/` + params.userId,
        method: "get",
        params
    });
}